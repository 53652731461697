<template>
  <base-modal-view
    title="Are you sure?"
    message="You are trying to make this request with no date filters set. This will process your all time data and may take a long time. Would you like to continue?"
    @cancel="cancelClicked"
  >
    <div>
      <p class="body-2-bold margin-top-1-05 margin-bottom-05">Set Date Filters</p>
      <div class="flex-row">
        <div class="flex-column">
          <label class="label">From Date</label>
          <input type="date" class="date-inputs" v-model="searchStartDate" />
        </div>
        <p class="margin-left-05 margin-right-05 margin-bottom-025 margin-top-auto">-</p>
        <div class="flex-column">
          <label class="label">To Date</label>
          <input type="date" class="date-inputs" v-model="searchEndDate" />
        </div>
      </div>
    </div>
    <primary-button
      class="margin-top-1-05"
      :title="searchStartDate || searchEndDate ? 'Continue' : 'Continue with all time data'"
      v-on:click="continueClicked()"
    />
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";
import { setStringDateToStartOfDate, setStringDateToEndOfDate } from "../../../helpers/utilityHelpers";

import { mapActions } from "vuex";

export default {
  name: "NoDateFilterModal",
  components: { PrimaryButton, BaseModalView },
  data: function () {
    return {
      searchStartDate: null,
      searchEndDate: null
    };
  },
  methods: {
    cancelClicked: function () {
      this.$emit("cancel");
    },
    continueClicked: function () {
      if (this.searchStartDate) {
        let date = setStringDateToStartOfDate(this.searchStartDate);
        if (date) {
          this.addFilter({
            filter: {
              key: "date_time_created_gt",
              value: date.toISOString()
            }
          });
        }
      }
      if (this.searchEndDate) {
        let date = setStringDateToEndOfDate(this.searchEndDate);
        if (date) {
          this.addFilter({
            filter: {
              key: "date_time_created_lt",
              value: date.toISOString()
            }
          });
        }
      }
      this.$emit("continue");
    },
    ...mapActions({
      addFilter: "formStore/addFilter"
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";

.date-inputs {
  @extend .body-2;

  border: none;
  position: relative;
  height: 28px;
  background: none;
  display: flex;
}

.date-inputs:after {
  content: url("/assets/img/icon-calendar.svg");
  color: #555;

  height: 28px;
  width: 28px;
  margin-right: 2px;
  margin-left: -20px;
}

.date-inputs::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 28px;
  height: 28px;
  color: transparent;
  background: transparent;
}
</style>
