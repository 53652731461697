import { axiosInstance, endpoints } from "../axiosHandler";

function getReportsOverview(filters, cancelToken) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.reports + "overview/", {
        params: filters,
        cancelToken: cancelToken.token
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function getReportsBreakdown(filters, cancelToken, breakdown_string) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.reports + breakdown_string + "/", {
        params: filters,
        cancelToken: cancelToken.token
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function getDepartmentReport(filters, style) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.reports + "download-dept-report/", {
        params: Object.assign({}, filters, { style: style }),
        responseType: "blob"
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function getDealershipVolumeReport(filters, style) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.reports + "download-dealership-volume-report/", {
        params: Object.assign({}, filters, { style: style }),
        responseType: "blob"
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function getDealerVolumeReport(filters, style) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.reports + "download-dealer-volume-report/", {
        params: Object.assign({}, filters, { style: style }),
        responseType: "blob"
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function getTradedPurposeReport(filters, style) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.reports + "download-traded-purpose-report/", {
        params: Object.assign({}, filters, { style: style }),
        responseType: "blob"
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function getPPSRReport(filters, style) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.reports + "download-ppsr-report/", {
        params: Object.assign({}, filters, { style: style }),
        responseType: "blob"
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function getDailyReport(filters, style) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.reports + "download-daily-report/", {
        params: Object.assign({}, filters, { style: style }),
        responseType: "blob"
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function getResellerReport(filters, style) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.reports + "download-reseller-report/", {
        params: Object.assign({}, filters, { style: style }),
        responseType: "blob"
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function getAdjustedValueReport(filters, style) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.reports + "download-adjusted-value-report/", {
        params: Object.assign({}, filters, { style: style }),
        responseType: "blob"
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function getSalesDifferenceReport(filters, style) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.reports + "download-diff-to-sales-value-report/", {
        params: Object.assign({}, filters, { style: style }),
        responseType: "blob"
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function getCustomerWantedReport(filters, style) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.reports + "download-customer-wanted-report/", {
        params: Object.assign({}, filters, { style: style }),
        responseType: "blob"
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function getReasonsLostReport(filters, style) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.reports + "download-reason-lost-report/", {
        params: Object.assign({}, filters, { style: style }),
        responseType: "blob"
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function getOnInternetReport(filters, style) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.reports + "download-on-internet-report/", {
        params: Object.assign({}, filters, { style: style }),
        responseType: "blob"
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export {
  getReportsOverview,
  getReportsBreakdown,
  getDepartmentReport,
  getDealershipVolumeReport,
  getDealerVolumeReport,
  getTradedPurposeReport,
  getPPSRReport,
  getDailyReport,
  getResellerReport,
  getAdjustedValueReport,
  getSalesDifferenceReport,
  getCustomerWantedReport,
  getReasonsLostReport,
  getOnInternetReport
};
